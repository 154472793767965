/**
 * Imports
 */
@import 'variables';
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import "node_modules/ngx-toastr/toastr.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

/**
 * Elements
 */
body {
  background-color: $background;
  padding-top: 100px;
}

table {
  background: $background
}

thead {
  text-transform: uppercase;
  color: $yellow
}

th {
  font-weight: normal;
  border: none !important;
  background: $background
}

tbody {
  border: none !important;
}

tr {
  border-top: none !important;
  border-bottom: 2px solid;
  border-color: rgba(249, 181, 74, 0.5) !important;
}

td {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

/**
 * Classes
 */
 .bg-color {
  background-color: $background;
}

// Alert
.alert-yellow {
  color: $background;
  background-color: $yellow;
}

// Buttons
.btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus, .btn-green:visited, .btn-green:disabled {
  color: $background;
  background: $green;
}

.btn-yellow, .btn-yellow:hover, .btn-yellow:active, .btn-yellow:focus, .btn-yellow:visited, .btn-yellow:disabled {
  color: $background;
  background: $yellow;
}

.btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus, .btn-red:visited, .btn-red:disabled {
  color: white;
  background: $red;
}

// Card
.card {
  background-color: $background;
  border: 1px solid $yellow;
}

.card-fixed-size {
  width: 560px;
  max-width: 560px;
  border: none;
}

.card-body {
  background: $background;
  border: 1px solid;
  border-top: none;
  border-color: $yellow;
}

.card-header {
  background: $background;
  color:  $yellow;
  border-bottom: 1px solid;
  border-color: $yellow;
  text-align: center;
}

// Modal
.modal-content {
  background-color: $background;
}

.modal-header {
  border-bottom: 1px solid gray;
}

.modal-footer {
  border-top: 1px solid gray;
}

// Text
.uppercase {
  text-transform: uppercase;
}

.text-red {
  color: $red;
}

.text-yellow {
  color: $yellow-1
}

.border-yellow {
  border: 2px solid $yellow-1;
  border-radius: 0.25rem;
}
